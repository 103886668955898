import { DevTools, Tolgee, LanguageStorage, FormatSimple } from "@tolgee/react";
import translationEN from "./locale/en.json";
import translationSV from "./locale/sv.json";
import translationNO from "./locale/no.json";

//Should match with Tolgees language codes for devmode to work correctly
export const LanguageMap = {
    en: {
        fullName: "English",
        name: "LOGIN_SELECT_ENGLISH",
    },
    sv: {
        fullName: "Swedish",
        name: "LOGIN_SELECT_SWEDISH",
    },
    no: {
        fullName: "Norwegian",
        name: "LOGIN_SELECT_NORWEGIAN",
    },
} as const;

export type Language = keyof typeof LanguageMap;

export const Languages = Object.keys(LanguageMap).map(k => ({ value: k, ...LanguageMap[k as Language] }));

export const tolgee = Tolgee()
    .use(FormatSimple())
    .use(DevTools())
    .use(LanguageStorage())
    .init({
        apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
        apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
        projectId: 2765,
        defaultLanguage: "en",
        fallbackLanguage: import.meta.env.MODE !== "localdev" ? "en" : undefined,
        //availableLanguages: ... Languages which can be used for language detection and also limits which values can be stored. Is derived from staticData keys if not provided.
        staticData: {
            en: translationEN,
            sv: translationSV,
            no: translationNO,
        },
    });
