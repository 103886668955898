import { MRT_Localization_SV } from "mantine-react-table/locales/sv";
import { MRT_Localization_EN } from "mantine-react-table/locales/en";
import { MRT_Localization_NO } from "mantine-react-table/locales/no";
import { MRT_Localization } from "mantine-react-table";
import { useTolgee } from "@tolgee/react";
import { Language } from "../../translation";
import { exhaustiveCheck } from "../../common/exhaustiveCheck";

export const useTableLocalization = (): MRT_Localization => {
    const tolgee = useTolgee(["language"]);

    const language = tolgee.getLanguage() as Language;

    switch (language) {
        case "en":
            return MRT_Localization_EN;
        case "sv":
            return MRT_Localization_SV;
        case "no":
            return MRT_Localization_NO;
        default:
            return exhaustiveCheck(language);
    }
};
